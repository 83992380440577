import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';
import Button from 'UI/components/Button';
import SectionTitle from 'UI/components/SectionTitle';
import { REVEAL_ANIMATION_PROPS, ROUTES } from 'utils/constants';
import { useBlog } from './utils/useBlog';
import styles from './styles.module.scss';

const Animated = dynamic(() => import('UI/containers/Animated'));
const BlogCard = dynamic(() => import('UI/components/Cards/BlogCard'));

const Blog = (props) => {
  const {
    title,
    description,
    articles,
  } = useBlog(props);

  return (
    <section className={styles.blog}>
      <div className={styles.contentWrapper}>
        <SectionTitle
          title={title}
          description={description}
          titleStyle={styles.titleStyle}
        />
        <div className={styles['card-list']}>
          {articles?.map((article, index) => (
            <BlogCard
              key={index}
              {...article}
            />
          ))}
        </div>

        <Button
          secondary
          href={ROUTES.blog.path}
          className={styles.blogButton}
        >
          Read more
        </Button>
      </div>
    </section>
  );
};

Blog.propTypes = {
  sectionData: PropTypes.instanceOf(Object).isRequired,
};

export default Blog;
