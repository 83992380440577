import blogApi from 'redux/apis/blog';
import { getDocumentFields } from 'utils/helper';

export const useBlog = ({ sectionData, blogQuery }) => {
  const { data = {} } = blogApi.useGetArticlesListQuery(blogQuery);
  const { items: articles = [] } = data;

  const { title, description } = getDocumentFields(sectionData, ['title', 'description']);

  return {
    title,
    articles,
    description,
  };
};
